import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ApplicationModal = ({ isOpen, onClose, handleSubmit }) => {
    // Initialize state for checkboxes
    const [checkboxes, setCheckboxes] = useState({
        certFacts: false,
        backgroundCheck: false,
        authorization: false,
        atWillEmployment: false,
    });

    // If the modal is not open, return null
    if (!isOpen) return null;

    // Handle checkbox changes
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxes((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    // Check if all checkboxes are checked
    const allChecked = Object.values(checkboxes).every((checked) => checked);

    const handleButtonClick = () => {
        if (allChecked) {
            handleSubmit(); // Call the handleSubmit function
            onClose(); // Close the modal
        } else {
            alert('Please agree to all statements before submitting.');
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 font-twcen overflow-y-auto"
        >
            <motion.div
                initial={{ y: -250 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.7 }}
                className="bg-white rounded-3xl shadow py-8 xl:px-12 px-5 w-3/4 xl:w-9/12 max-h-full overflow-y-auto"
            >
                <div className="text-center">
                    <h2 className="text-5xl text-mainColor mb-4 text-center">Authorization</h2>
                    <div className="text-justify mb-2">
                        <p>I certify that the facts obtained in this application (and accompanying resume, if any) are true and complete. I understand that any false statement, omission, or misrepresentation on this application is sufficient cause for refusal to hire, or dismissal if I have been hired, no matter when discovered by the Company.</p>
                        <input
                            type="checkbox"
                            id="certFacts"
                            name="certFacts"
                            checked={checkboxes.certFacts}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="certFacts" className="ml-2 text-mainColor">I agree to this statement</label>
                    </div>
                    <div className="text-justify mb-2">
                        <p>I understand that any employment is conditioned on a background check and my ability and willingness to attest to my identity and employment eligibility and the presentation of any documents deemed necessary by the company to verify my identity and employment eligibility.</p>
                        <input
                            type="checkbox"
                            id="backgroundCheck"
                            name="backgroundCheck"
                            checked={checkboxes.backgroundCheck}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="backgroundCheck" className="ml-2 text-mainColor">I agree to this statement</label>
                    </div>
                    <div className="text-justify mb-2">
                        <p>I authorize the Company to thoroughly investigate all statements contained in my application or resume, and I authorize my former employers and references to disclose information regarding my former employment, character, and general reputation to the Company, without giving me prior notice of such disclosure. In addition, I release the Company, any former employers, and all references listed above or other references provided from any and all claims, demands, or liabilities arising out of or related to such investigation or disclosure.</p>
                        <input
                            type="checkbox"
                            id="authorization"
                            name="authorization"
                            checked={checkboxes.authorization}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="authorization" className="ml-2 text-mainColor">I agree to this statement</label>
                    </div>
                    <div className="text-justify mb-2">
                        <p>I understand and agree that nothing contained in this application, or conveyed during any interview, is intended to create an employment contract. I further understand and agree that if I am hired, my employment will be “at will” and without a fixed term, and may be terminated at any time, with or without cause and without prior notice, at the option of either myself or the Company. No promises regarding employment have been made to me, and I understand that no such promise or guarantee is binding upon the Company unless made in writing.</p>
                        <input
                            type="checkbox"
                            id="atWillEmployment"
                            name="atWillEmployment"
                            checked={checkboxes.atWillEmployment}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="atWillEmployment" className="ml-2 text-mainColor">I agree to this statement</label>
                    </div>
                    <div className="mt-4">
                        <button
                            onClick={handleButtonClick}
                            disabled={!allChecked} // Disable button if not all checkboxes are checked
                            className={`bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-8 rounded focus:outline-none focus:shadow-outline ${!allChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default ApplicationModal;
