import React, { useState } from 'react';
import axios from 'axios';

const CareerForm = () => {
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        ContactNumber: '',
        Email: '',
        FileUpload: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'FileUpload') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form data:', formData);

        const data = new FormData();
        data.append('files.FileUpload', formData.FileUpload);
        data.append('data', JSON.stringify({
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            ContactNumber: formData.ContactNumber,
            Email: formData.Email,
        }));

        try {
            // Push form data to Strapi
            const response = await axios.post('https://strapi.loveandfaithhospice.com/api/careers-forms', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Response from Strapi:', response.data);

            // Send email with form data
            const emailResponse = await axios.post('https://loveandfaithhospice.com:5001/send-email', {
                formType: 'career',
                formData: {
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                    ContactNumber: formData.ContactNumber,
                    Email: formData.Email,
                    FileUpload: formData.FileUpload.name,
                },
            });
            console.log('Response from email API:', emailResponse.data);

            alert('Your application has been submitted successfully!');

            // Refresh the page after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting your application. Please try again later.');
        }
    };

    return (
        <div className='bg-white px-10 py-3 shadow-lg' style={{ borderRadius: "24px" }}>
            <div className=''>
                <h2 className="mx-4 mt-4 text-4xl text-shadow-lg">WORK WITH US</h2>
                <h2 className="mx-4 mb-4 pb-5 text-5xl leading-none text-shadow-lg text-mainColor">JOIN OUR TEAM</h2>
                <p className="mx-4 mb-4 text-2xl">
                    If you are interested to be part of our team,<br />you can send us your details and we'll get back to you.
                </p>
            </div>
            <div className="max-w-xl bg-mainColor mx-auto" style={{ borderRadius: "24px" }}>
                <form onSubmit={handleSubmit} className="rounded px-8 pt-6 pb-8 mb-4">
                    <div className="mb-4">
                        <label className="block text-white text-2xl font-bold mb-2" htmlFor="FirstName">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="FirstName"
                            name="FirstName"
                            value={formData.FirstName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-2xl font-bold mb-2" htmlFor="LastName">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="LastName"
                            name="LastName"
                            value={formData.LastName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-2xl font-bold mb-2" htmlFor="ContactNumber">
                            Contact Number
                        </label>
                        <input
                            type="tel"
                            id="ContactNumber"
                            name="ContactNumber"
                            value={formData.ContactNumber}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-2xl font-bold mb-2" htmlFor="Email">
                            Email
                        </label>
                        <input
                            type="email"
                            id="Email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-2xl font-bold mb-2" htmlFor="FileUpload">
                            File Upload (PDF/Word)
                        </label>
                        <input
                            type="file"
                            id="FileUpload"
                            name="FileUpload"
                            accept=".pdf,.doc,.docx"
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-3/4 py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-center mt-6">
                        <button
                            type="submit"
                            className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CareerForm;
