import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = () => {
    const location = useLocation();

    // Function to format the path
    const formatPath = (path) => {
        // Split the path into segments
        const pathSegments = path.split('/').filter(Boolean);

        // Capitalize the first letter of each segment
        const formattedSegments = pathSegments.map(segment =>
            segment.charAt(0).toUpperCase() + segment.slice(1)
        );

        // Join the segments with " > "
        return formattedSegments.join(' > ');
    };

    return (
        <div className="breadcrumbs text-2xl">
            <Link to="/" className="text-mainColor hover:underline">Home</Link>
            {location.pathname !== '/' && (
                <>
                    <span className="text-lg mb-2"> &gt; </span>
                    <span className="text-2xl">
                        {formatPath(location.pathname)}
                    </span>
                </>
            )}
        </div>
    );
};

export default Breadcrumbs;
