import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import image1 from '../assets/slider1.jpg';
import image2 from '../assets/slider2.jpg';
import image3 from '../assets/slider3.jpg';

const buttonStyle = {
    width: "0px",
    background: 'none',
    border: '0px'
};

const properties = {
    duration: 3000,
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" /></svg></button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z" /></svg></button>,
};

const fadeImages = [
    {
        url: image1,
        caption: 'First Slide',
    },
    {
        url: image2,
        caption: 'Second Slide',
    },
    {
        url: image3,
        caption: 'Third Slide',
    },
];

const Slideshow = () => (
    <div className='bg-neutral-800'>
        <div className="slide-container bg-black opacity-70">
            <Fade {...properties}>
                {fadeImages.map((fadeImage, index) => (
                    <div key={index}>
                        <img style={{ width: '100%' }} src={fadeImage.url} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Fade>
        </div>
    </div>
);

export default Slideshow;
