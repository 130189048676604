import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import Blazy from 'blazy';
import HeaderHero from '../components/headerhero_section';
import HeaderImage from '../assets/hospiceservices.jpg';
import Breadcrumbs from '../components/breadcrumbs';
import CoverageImage from '../assets/coverage.png';
import BereavementImage from '../assets/bereavement.jpg';
import VolunteerForm from '../components/forms/volunteerform';
import VolunteerImage from '../assets/volunteer.jpg';

const HospiceServices = () => {
    useEffect(() => {
        const bLazy = new Blazy({
            selector: '.b-lazy',
        });

        return () => bLazy.destroy();
    }, []);

    const options = {
        triggerOnce: true,
        threshold: 0.1
    };

    return (
        <div className='font-twcen border-b-1 border-black shadow-md xl:mt-0 mt-28'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <HeaderHero
                    imageUrl={HeaderImage}
                    title="Hospice Services"
                />
            </motion.div>
            <div className='flex flex-col xl:w-3/4 w-full items-center justify-center mx-auto px-5 xl:px-0'>
                <div className='w-full items-center text-center xl:text-left mt-5'>
                    <Breadcrumbs />
                </div>
                <div className='w-full border-b-2 border-dashed border-gray-500 mt-4'></div>

                {/* Desktop Benefits*/}
                <InView {...options}>
                    {({ inView, ref }) => (
                        <motion.div
                            id="benefits-desktop"
                            className="hidden text-black"
                            ref={ref}
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7 }}
                        >
                            <div className="flex flex-row justify-around items-center rounded-3xl my-3 py-5">
                                <div className='ml-5'>
                                    <h2 className="text-5xl pb-5 font-semibold text-mainColor text-center">
                                        LOVE AND FAITH HOSPICE SERVICES BENEFITS
                                    </h2>
                                    <ul className="list-disc text-2xl text-left">
                                        <li>Benefit #1</li>
                                        <li>Benefit #2</li>
                                        <li>Benefit #3</li>
                                        <li>Benefit #4</li>
                                        <li>Benefit #5</li>
                                    </ul>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </InView>

                {/* Desktop Mission Statement*/}
                <InView {...options}>
                    {({ inView, ref }) => (
                        <motion.div
                            id="coverage-desktop"
                            className="hidden xl:block text-black"
                            ref={ref}
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7 }}
                        >
                            <div className="flex flex-row justify-around items-center rounded-3xl my-3 py-5 ">
                                <div className='mx-5'>
                                    <img src={CoverageImage} alt="coverage" className="max-h-40 w-auto rounded-3xl" />
                                </div>
                                <div className='mx-5'>
                                    <h2 className="text-5xl pb-5 font-semibold text-mainColor text-left -ml-6">
                                        OUR COVERAGE AREAS
                                    </h2>
                                    <div className="flex">
                                        <ul className="list-disc text-2xl text-left flex-1">
                                            <li>Los Angeles County</li>
                                            <li>Riverside County</li>
                                        </ul>
                                        <ul className="list-disc text-2xl text-left flex-1">
                                            <li>San Bernardino County</li>
                                            <li>Orange County</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </InView>

                {/* Mobile Mission Statement*/}
                <div id="coverage-mobile" className="block xl:hidden text-black">
                    <motion.div
                        className="flex flex-col justify-around items-center rounded-3xl my-3 py-5"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='mx-5 mb-5'>
                            <img src={CoverageImage} alt="coverage" className="max-h-40 w-auto rounded-3xl" />
                        </div>
                        <div className='px-5'>
                            <h2 className="text-3xl pb-5 font-semibold text-mainColor text-center">
                                OUR COVERAGE AREAS
                            </h2>
                            <div className='items-center justify-center mx-auto px-10'>
                                <ul className="list-disc text-2xl text-left">
                                    <li>Los Angeles County</li>
                                    <li>Riverside County</li>
                                    <li>San Bernardino County</li>
                                    <li>Orange County</li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                </div>

                {/* Desktop Hospice Levels of Care*/}
                <InView {...options}>
                    {({ inView, ref }) => (
                        <motion.div
                            id="hospice-desktop"
                            className="hidden xl:block text-black pb-5"
                            style={{ borderRadius: "40px" }}
                            ref={ref}
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 0.8 }}
                        >
                            <div
                                className="flex flex-col justify-around items-center rounded-3xl my-3 py-5"
                            >
                                <h2 className="text-5xl pb-5 font-semibold text-mainColor text-center">
                                    HOSPICE LEVELS OF CARE
                                </h2>
                                <div className="flex flex-wrap justify-center items-center mx-2 my-2 text-center">
                                    <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                        <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                            <div>
                                                <h2 className="text-4xl font-semibold mb-5">Routine Home Care</h2>
                                                <p className="text-xl leading-none">
                                                    Patient is generally stable and the patient's symptoms are controlled. Hospice Disciplines will provide the care and frequency of visits as ordered by the hospice physician.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                        <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                            <div>
                                                <h2 className="text-4xl font-semibold mb-5">General In Patient Care</h2>
                                                <p className="text-xl leading-none">
                                                    A Crisis-like level of care for short-term management of out of control patient pain and/or symptoms provided in an inpatient facility setting (hospital or skilled nursing facility).
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                        <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                            <div>
                                                <h2 className="text-4xl font-semibold mb-5">Continuous Home Care</h2>
                                                <p className="text-xl leading-none">
                                                    A Crisis-like level of care for short-term management of out of control patient pain and/or symptoms. Hospice will provide at least 8-hour nursing care at bedside in the patient home/assisted living or board and care facility.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                        <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                            <div>
                                                <h2 className="text-4xl font-semibold mb-5">Respite Care</h2>
                                                <p className="text-xl leading-none">
                                                    A level of temporary care provided in nursing home, hospice inpatient facility, or hospital so that a family member or friend who's the patient's caregiver can take some time off. This level of care is tied to caregiver needs, not patient symptoms. Respite care can be offered as needed for 3 to 5 days maximum depending on bed availability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </InView>

                {/* Mobile Hospice Levels of Care*/}
                <div id="hospice-mobile" className="xl:hidden block text-black">
                    <motion.div
                        className="flex flex-col justify-around items-center rounded-3xl my-3 py-5"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-3xl font-semibold text-mainColor text-center">
                            HOSPICE LEVELS OF CARE
                        </h2>
                        <div className="flex flex-wrap justify-center items-center my-2 text-center">
                            <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3 w-full" style={{ borderRadius: "24px" }}>
                                <div className="flex flex-col h-auto justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4 py-2 w-auto" style={{ borderRadius: "36px" }}>
                                    <div>
                                        <h2 className="text-xl font-semibold">Routine Home Care</h2>
                                        <p className="text-lg leading-normal py-1">
                                            Patient is generally stable and the patient's symptoms are controlled. Hospice Disciplines will provide the care and frequency of visits as ordered by the hospice physician.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3 w-full" style={{ borderRadius: "24px" }}>
                                <div className="flex flex-col h-auto justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4 py-2 w-auto" style={{ borderRadius: "36px" }}>
                                    <div>
                                        <h2 className="text-xl font-semibold">General In Patient Care</h2>
                                        <p className="text-lg leading-normal py-1">
                                            A Crisis-like level of care for short-term management of out of control patient pain and/or symptoms provided in an inpatient facility setting (hospital or skilled nursing facility).
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3 w-full" style={{ borderRadius: "24px" }}>
                                <div className="flex flex-col h-auto justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4 py-2 w-auto" style={{ borderRadius: "36px" }}>
                                    <div>
                                        <h2 className="text-xl font-semibold">Continuous Home Care</h2>
                                        <p className="text-lg leading-normal py-1">
                                            A Crisis-like level of care for short-term management of out of control patient pain and/or symptoms. Hospice will provide at least 8-hour nursing care at bedside in the patient home/assisted living or board and care facility.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3 w-full" style={{ borderRadius: "24px" }}>
                                <div className="flex flex-col h-auto justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4 py-2 w-auto" style={{ borderRadius: "36px" }}>
                                    <div>
                                        <h2 className="text-xl font-semibold">Respite Care</h2>
                                        <p className="text-lg leading-normal py-1">
                                            A level of temporary care provided in nursing home, hospice inpatient facility, or hospital so that a family member or friend who's the patient's caregiver can take some time off. This level of care is tied to caregiver needs, not patient symptoms. Respite care can be offered as needed for 3 to 5 days maximum depending on bed availability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>

                {/* Desktop Bereavement*/}
                <InView {...options}>
                    {({ inView, ref }) => (
                        <motion.div
                            id="bereavement-desktop"
                            className="hidden xl:block text-black"
                            ref={ref}
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7 }}
                        >
                            <div className='w-1/2 mx-auto -mb-5 py-3 items-center border-4 border-secondaryColor rounded-t-3xl'>
                                <h2 className="text-5xl font-semibold text-mainColor text-center">
                                    BEREAVEMENT
                                </h2>
                            </div>
                            <div className="flex flex-row justify-around items-center rounded-3xl my-3 py-5 bg-mainColor">
                                <div className='max-w-md mx-5 pl-5'>
                                    <p className="text-white text-xl leading-none text-right">
                                        We offer a comprehensive Bereavement program to the families of a deceased patient. Our 13- month bereavement program offers support and resources to the grieving family. Bereavement Services are delivered through Group Sessions, telehealth or in person visit by our Bereavement Counselor.
                                    </p>
                                </div>
                                <div className='mx-5 pr-5'>
                                    <img src={BereavementImage} alt="bereavement" className="max-h-40 w-auto rounded-3xl" />
                                </div>
                            </div>
                        </motion.div>
                    )}
                </InView>

                {/* Mobile Bereavement*/}
                <div id="bereavement-mobile" className="block xl:hidden">
                    <motion.div
                        className="w-3/4 mx-auto -mb-5 py-3 items-center border-4 border-secondaryColor rounded-t-3xl"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="text-3xl font-semibold text-mainColor text-center">
                            BEREAVEMENT
                        </h2>
                    </motion.div>
                    <div className="flex flex-col justify-around items-center rounded-3xl my-3 py-5 bg-mainColor">
                        <div className='mx-5'>
                            <img src={BereavementImage} alt="bereavement" className="max-h-52 w-auto rounded-3xl" />
                        </div>
                        <div className='max-w-md mx-5 mt-5'>
                            <p className="text-white text-xl leading-normal text-center">
                                We offer a comprehensive Bereavement program to the families of a deceased patient. Our 13- month bereavement program offers support and resources to the grieving family. Bereavement Services are delivered through Group Sessions, telehealth or in person visit by our Bereavement Counselor.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Desktop Volunteer*/}
                <InView {...options}>
                    {({ inView, ref }) => (
                        <motion.div
                            id="volunteer-desktop"
                            className="hidden xl:block text-black"
                            ref={ref}
                            initial={{ opacity: 0, y: 50 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7 }}
                        >
                            <div className="flex flex-col justify-around items-center my-3 pt-5 pb-10">
                                <div className='w-4/5 mb-8'>
                                    <h2 className="text-5xl pb-5 font-semibold text-mainColor text-center">
                                        VOLUNTEER PROGRAM
                                    </h2>
                                    <p className="text-2xl leading-none text-justify">
                                        Volunteers are essential part of the Hospice Program. They play a vital role in enhancing the quality of care delivered to patients and families.
                                    </p>
                                </div>
                                <div className='flex flex-row items-center mb-8'>
                                    <div className='max-w-md ml-10'>
                                        <h2 className="text-3xl font-semibold text-mainColor text-center">
                                            Hospice Volunteers Roles:
                                        </h2>
                                        <ul className="list-disc text-2xl text-balanced leading-relaxed">
                                            <li>Provide support and companionship to the patient and his or her family</li>
                                            <li>Supportive calls / visits to the surviving family who just lost a loved one</li>
                                            <li>Respite, Caregiver relief</li>
                                            <li>Reading, Writing letters, Listening to music</li>
                                            <li>Conversations</li>
                                            <li>Taking walks</li>
                                            <li>Light household task</li>
                                            <li>Errands</li>
                                        </ul>
                                    </div>
                                    <div className='w-auto ml-3 mr-5'>
                                        <img src={VolunteerImage} alt="volunteer" className="w-auto rounded-3xl" style={{ maxHeight: '420px' }} />
                                    </div>
                                </div>
                                <div className='w-full mx-5'>
                                    <VolunteerForm />
                                </div>
                            </div>
                        </motion.div>
                    )}
                </InView>

                {/* Mobile Volunteer*/}
                <div id="volunteer-mobile" className="block xl:hidden">
                    <motion.div
                        className="flex flex-col justify-around items-center my-3 pt-5 pb-10"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='w-full px-5 mb-4'>
                            <h2 className="text-3xl pb-5 font-semibold text-mainColor text-center">
                                VOLUNTEER PROGRAM
                            </h2>
                            <p className="text-xl leading-normal text-justify">
                                Volunteers are essential part of the Hospice Program. They play a vital role in enhancing the quality of care delivered to patients and families.
                            </p>
                        </div>
                        <div className='flex flex-col items-center justify-center mb-8 mx-auto'>
                            <div className='max-w-md flex justify-center px-2'>
                                <img src={VolunteerImage} alt="volunteer" className="w-full rounded-3xl mb-5" />
                            </div>
                            <div className='max-w-md ml-5 px-2'>
                                <h2 className="text-2xl font-semibold text-mainColor text-center mb-2">
                                    Hospice Volunteers Roles:
                                </h2>
                                <ul className="list-disc text-xl text-balanced leading-relaxed">
                                    <li>Provide support and companionship to the patient and his or her family</li>
                                    <li>Supportive calls / visits to the surviving family who just lost a loved one</li>
                                    <li>Respite, Caregiver relief</li>
                                    <li>Reading, Writing letters, Listening to music</li>
                                    <li>Conversations</li>
                                    <li>Taking walks</li>
                                    <li>Light household task</li>
                                    <li>Errands</li>
                                </ul>
                            </div>
                        </div>
                        <div className='w-full mx-5'>
                            <VolunteerForm />
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default HospiceServices;
