import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Home from './pages/home';
import AboutUs from './pages/about_us';
import HospiceServices from './pages/hospice_services';
import HealthCareTeam from './pages/healthcare_team';
import ContactUs from './pages/contact_us';
import Navi from './components/navigation_bar';
import Footer from './components/footer_bar';
import ApplicationForm from './components/forms/digitized forms/applicationform';

const AnimatedRoutes = () => {
  const location = useLocation();

  const pageTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 1 },
  };

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageTransition}>
              <Home />
            </motion.div>
          }
        />
        <Route
          path="/about"
          element={
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageTransition}>
              <AboutUs />
            </motion.div>
          }
        />
        <Route
          path="/services"
          element={
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageTransition}>
              <HospiceServices />
            </motion.div>
          }
        />
        <Route
          path="/team"
          element={
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageTransition}>
              <HealthCareTeam />
            </motion.div>
          }
        />
        <Route
          path="/contact"
          element={
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageTransition}>
              <ContactUs />
            </motion.div>
          }
        />
        <Route
          path="/application-form"
          element={
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageTransition}>
              <ApplicationForm />
            </motion.div>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  useEffect(() => {
    const errorHandler = (e) => {
      if (e.message.includes("ResizeObserver loop completed with undelivered notifications")) {
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  return (
    <Router>
      <div>
        <Navi />
        <AnimatedRoutes />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
