import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import Blazy from 'blazy';
import HeaderHero from '../../headerhero_section';
import HeaderImage from '../../../assets/healthcareteam.jpg';
import Breadcrumbs from '../../breadcrumbs';
import ApplicationModal from '../../applicationmodal';
import axios from 'axios';
import samplePDF from '../../../assets/Application for Employment.pdf';
import { PDFDocument } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import * as pdfjsLib from 'pdfjs-dist';
/* eslint-disable-next-line no-unused-vars */
import worker from 'pdfjs-dist/webpack';

const ApplicationForm = () => {
    const [formData, setFormData] = useState({
        date: '',
        lastName: '',
        firstName: '',
        middleName: '',
        presentStreet: '',
        presentCity: '',
        presentState: '',
        presentZip: '',
        emergencyContactName: '',
        emergencyContactRelationship: '',
        emergencyContactPhone: '',
        phoneDay: '',
        phoneEvening: '',
        referredBy: '',
        position: '',
        startDate: '',
        desiredSalary: '',
        employedNow: null,
        contactEmployer: null,
        appliedBefore: null,
        whenApplied: '',
        employer1FromDate: '',
        employer1ToDate: '',
        employer1Name: '',
        employer1Address: '',
        employer1Phone: '',
        employer1Salary: '',
        employer1Position: '',
        employer1Reason: '',
        employer2FromDate: '',
        employer2ToDate: '',
        employer2Name: '',
        employer2Address: '',
        employer2Phone: '',
        employer2Salary: '',
        employer2Position: '',
        employer2Reason: '',
        employer3FromDate: '',
        employer3ToDate: '',
        employer3Name: '',
        employer3Address: '',
        employer3Phone: '',
        employer3Salary: '',
        employer3Position: '',
        employer3Reason: '',
        employer4FromDate: '',
        employer4ToDate: '',
        employer4Name: '',
        employer4Address: '',
        employer4Phone: '',
        employer4Salary: '',
        employer4Position: '',
        employer4Reason: '',
        ref1Name: '',
        ref1Phone: '',
        ref1Relationship: '',
        ref1YearsAcq: '',
        ref2Name: '',
        ref2Phone: '',
        ref2Relationship: '',
        ref2YearsAcq: '',
        ref3Name: '',
        ref3Phone: '',
        ref3Relationship: '',
        ref3YearsAcq: ''
    });
    const [signatureFieldCoordinates, setSignatureFieldCoordinates] = useState(null);
    const [signature, setSignature] = useState(null);
    const sigCanvas = useRef({});

    useEffect(() => {
        // Load PDF and extract SignatureField coordinates using pdfjs
        const loadPDFAndExtractCoordinates = async () => {
            const loadingTask = pdfjsLib.getDocument(samplePDF);
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(2);
            const annotations = await page.getAnnotations();

            const signatureAnnotation = annotations.find(annotation => annotation.fieldName === 'SignatureField');

            if (signatureAnnotation) {
                const { rect } = signatureAnnotation;

                // Log the raw rectangle data
                console.log("Raw rectangle data for SignatureField:", rect);

                // Calculate coordinates and dimensions
                const signatureFieldData = {
                    x: rect[0],
                    y: rect[1],
                    width: rect[2] - rect[0],
                    height: rect[3] - rect[1],
                };

                // Log the calculated coordinates and dimensions
                console.log("Calculated SignatureField coordinates and dimensions:", signatureFieldData);

                // Set state with the calculated coordinates
                setSignatureFieldCoordinates(signatureFieldData);
            } else {
                console.warn("Signature field not found in the PDF annotations.");
            }

        };

        loadPDFAndExtractCoordinates();
    }, []);

    // Save the signature as a base64 image
    const handleSignatureSave = () => {
        if (!sigCanvas.current.isEmpty()) {
            // Get the raw image data from the canvas
            const canvas = sigCanvas.current.getCanvas();
            const context = canvas.getContext('2d');
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const { data, width, height } = imageData;

            // Variables to track the signature bounds
            let minX = width, minY = height, maxX = 0, maxY = 0;

            // Loop through pixel data to find the bounding box of the signature
            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {
                    const index = (y * width + x) * 4;
                    const alpha = data[index + 3]; // Alpha channel

                    if (alpha > 0) { // Non-empty pixel found
                        minX = Math.min(minX, x);
                        minY = Math.min(minY, y);
                        maxX = Math.max(maxX, x);
                        maxY = Math.max(maxY, y);
                    }
                }
            }

            // Calculate the dimensions of the cropped area
            const cropWidth = maxX - minX;
            const cropHeight = maxY - minY;

            // Create a new canvas to hold the cropped signature
            const croppedCanvas = document.createElement('canvas');
            croppedCanvas.width = cropWidth;
            croppedCanvas.height = cropHeight;

            // Draw the cropped signature onto the new canvas
            const croppedContext = croppedCanvas.getContext('2d');
            croppedContext.drawImage(
                canvas,
                minX, minY, cropWidth, cropHeight, // Source coordinates and size
                0, 0, cropWidth, cropHeight // Destination coordinates and size
            );

            // Convert the cropped canvas to a base64 image URL
            const signatureDataUrl = croppedCanvas.toDataURL('image/png');
            setSignature(signatureDataUrl);
            console.log("Cropped signature saved successfully:", signatureDataUrl);
        } else {
            console.log("Signature canvas is empty.");
        }
    };

    // Ensure fillPDF is called in a place where signature is guaranteed to be set.
    useEffect(() => {
        if (signature) {
            fillPDF(); // Only call fillPDF if a signature exists
        }
    }, [signature]);

    // Clear the signature from the canvas
    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
        console.log("Signature cleared.");
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    /// Fill the PDF with form data and signature
    const fillPDF = async () => {
        try {
            const formPdfBytes = await fetch(samplePDF).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(formPdfBytes);
            const form = pdfDoc.getForm();

            // Fill other fields in the form
            const radioGroups = {
                employedNow: ['Yes', 'No'],
                contactEmployer: ['Yes', 'No'],
                appliedBefore: ['Yes', 'No'],
            };

            for (const [key, value] of Object.entries(formData)) {
                //console.log(`Processing field: ${key} with value: ${value}`);

                // Default value for radio buttons
                const radioValue = value || 'No'; // Use 'No' if value is null or empty

                if (radioGroups[key]) {
                    const radioOptions = radioGroups[key];
                    const radioGroup = form.getRadioGroup(key);
                    if (radioGroup) {
                        if (radioValue === 'Yes' && radioOptions[0]) {
                            radioGroup.select(radioOptions[0]);
                        } else if (radioValue === 'No' && radioOptions[1]) {
                            radioGroup.select(radioOptions[1]);
                        } else {
                            console.warn(`Unexpected value for radio group ${key}: ${radioValue}`);
                        }
                    } else {
                        console.warn(`Radio group ${key} not found in PDF`);
                    }
                } else {
                    const textField = form.getTextField(key);
                    if (textField) {
                        textField.setText(value || '');
                    } else {
                        console.warn(`Text field ${key} not found in PDF`);
                    }
                }
            }

            const signatureField = form.getField('SignatureField');
            if (!signatureField) {
                console.warn("Signature field not found in the PDF.");
                alert("Signature field is missing from the PDF.");
                return;
            }

            // Check if signature exists and handle it accordingly
            if (signature) {
                const signatureImageBytes = await fetch(signature).then(res => res.arrayBuffer());
                const signatureImageEmbed = await pdfDoc.embedPng(signatureImageBytes);

                const firstPage = pdfDoc.getPages()[1];

                // Get the original dimensions of the signature image
                const originalWidth = signatureImageEmbed.width;
                const originalHeight = signatureImageEmbed.height;

                // Calculate aspect ratios
                const signatureAspectRatio = originalWidth / originalHeight;
                const fieldAspectRatio = signatureFieldCoordinates.width / signatureFieldCoordinates.height;

                // Adjust the signature dimensions based on the aspect ratio of the field
                let width, height;
                if (signatureAspectRatio > fieldAspectRatio) {
                    // Signature is wider than the field, adjust by width
                    width = signatureFieldCoordinates.width;
                    height = width / signatureAspectRatio;
                } else {
                    // Signature is taller than the field, adjust by height
                    height = signatureFieldCoordinates.height;
                    width = height * signatureAspectRatio;
                }

                // Draw the signature image at the coordinates from pdfjs
                firstPage.drawImage(signatureImageEmbed, {
                    x: signatureFieldCoordinates.x,
                    y: signatureFieldCoordinates.y,
                    width,
                    height,
                });

                console.log("Signature placed in field: SignatureField");

            } else {
                console.warn("No signature found to place in the PDF.");
            }

            // Save the filled PDF
            const pdfBytes = await pdfDoc.save();
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

            const firstName = formData.firstName || 'NoFirstName';
            const lastName = formData.lastName || 'NoLastName';
            const customFilename = `${firstName}_${lastName}_application.pdf`;

            const formDataForUpload = new FormData();
            formDataForUpload.append('files', pdfBlob, customFilename);

            const uploadResponse = await axios.post('https://strapi.loveandfaithhospice.com/api/upload', formDataForUpload, {
                headers: {
                    'Authorization': `Bearer d8e21e0a6e63986bc0343d76c25ca4defc65d412efc2248a51a357bf3704a09ae9264a5b6508f8f58a45946ed8c97f40b5b3731544892457f5b1cbde97201c181a019fb1788d838c2ab4699b1987ded3328cf2e6b84df18c953b2224e3f1310c841474f6189f761419b81a60a7466cd1763973929b55ee490a403dd7f029fb9a`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            return uploadResponse.data[0].url;
        } catch (error) {
            console.error("Error in fillPDF:", error);
            alert("An error occurred while processing the PDF. Please try again.");
        }
    };

    const submitFormData = async (fileUrl) => {
        try {
            const response = await axios.post('https://strapi.loveandfaithhospice.com/api/application-forms', {
                data: formData
            });
            console.log('Response from Strapi:', response.data);

            const emailPayload = {
                formType: 'application',
                formData,
                fileUrl
            };

            if (fileUrl) {
                emailPayload.fileUrl = fileUrl;
            }

            const emailResponse = await axios.post('https://loveandfaithhospice.com:5001/send-email', emailPayload);
            console.log('Response from email API:', emailResponse.data);

            alert('Your application has been submitted successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting your application. Please try again later.');
        }
    };

    const handleSubmit = async () => {
        console.log('Form data:', formData);
        handleSignatureSave(); // Only save the signature, do not call fillPDF
        await submitFormData();
    };


    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setFormData(prevData => ({
            ...prevData,
            date: today
        }));

        const bLazy = new Blazy({ selector: '.b-lazy' });
        return () => bLazy.destroy();
    }, []);

    const [isApplicationModalOpen, setApplicationModalOpen] = useState(false);

    const handleButtonClick = async () => {
        setApplicationModalOpen(true);
    };

    return (
        <div className='font-twcen border-b-1 border-black shadow-md xl:mt-0 mt-28 bg-gray-200'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <HeaderHero
                    imageUrl={HeaderImage}
                    title="Application Form"
                />
            </motion.div>
            <div className='flex flex-col xl:w-3/4 w-full items-center justify-center mx-auto px-5 xl:px-0'>
                <div className='w-full items-center text-center xl:text-left mt-5'>
                    <Breadcrumbs />
                </div>
                <div className='w-full border-b-2 border-dashed border-gray-500 mt-4 mb-4'></div>

                <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                    <form onSubmit={(e) => e.preventDefault()}>

                        {/* Personal Information Section */}
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                            <div className="text-black">
                                <p className="font-medium text-xl">Personal Information</p>
                                <p>Please fill out all the fields.</p>
                            </div>

                            <div className="lg:col-span-2">
                                <p className='my-5'>We are an equal employer, dedicated to a policy of non-discrimination in employment on any basis including race, color, age, sex, religion, disability or national origin. Consistent with the Americans with Disabilities Act, applicants may request accommodations needed to participate in the application process. </p>

                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                    <div className="md:col-span-2">
                                        <label htmlFor="startDate">Date</label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.date}
                                            readOnly
                                        />
                                    </div>

                                    <div className="md:col-span-4">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-4">
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-4">
                                        <label htmlFor="middleName">Middle Name</label>
                                        <input
                                            type="text"
                                            name="middleName"
                                            id="middleName"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.middleName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-3">
                                        <label htmlFor="presentStreet">Street</label>
                                        <input
                                            type="text"
                                            name="presentStreet"
                                            id="presentStreet"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.presentStreet}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="presentCity">City</label>
                                        <input
                                            type="text"
                                            name="presentCity"
                                            id="presentCity"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.presentCity}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="presentState">State</label>
                                        <input
                                            type="text"
                                            name="presentState"
                                            id="presentState"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.presentState}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="presentZip">Zip</label>
                                        <input
                                            type="text"
                                            name="presentZip"
                                            id="presentZip"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.presentZip}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="phoneDay">Phone Day</label>
                                        <input
                                            type="text"
                                            name="phoneDay"
                                            id="phoneDay"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.phoneDay}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="phoneEvening">Phone Evening</label>
                                        <input
                                            type="text"
                                            name="phoneEvening"
                                            id="phoneEvening"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.phoneEvening}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="emergencyContactName">Emergency Contact Name</label>
                                        <input
                                            type="text"
                                            name="emergencyContactName"
                                            id="emergencyContactName"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.emergencyContactName}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="emergencyContactRelationship">Relationship</label>
                                        <input
                                            type="text"
                                            name="emergencyContactRelationship"
                                            id="emergencyContactRelationship"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.emergencyContactRelationship}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="emergencyContactPhone">Emergency Contact Phone</label>
                                        <input
                                            type="text"
                                            name="emergencyContactPhone"
                                            id="emergencyContactPhone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.emergencyContactPhone}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="referredBy">Referred By</label>
                                        <input
                                            type="text"
                                            name="referredBy"
                                            id="referredBy"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.referredBy}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Employment Desired Section */}
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 mt-8">
                            <div className="text-black">
                                <p className="font-medium text-xl">Employment Desired</p>
                                <p>Please fill out all the fields.</p>
                            </div>

                            <div className="lg:col-span-2">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

                                    <div className="md:col-span-2">
                                        <label htmlFor="position">Position</label>
                                        <input
                                            type="text"
                                            name="position"
                                            id="position"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.position}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="startDate">Date you can start</label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.startDate}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-1">
                                        <label htmlFor="desiredSalary">Desired Salary</label>
                                        <input
                                            type="text"
                                            name="desiredSalary"
                                            id="desiredSalary"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.desiredSalary}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="employedNow" className="block mb-2">Are you employed now?</label>
                                        <div className="flex items-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="employedNow"
                                                    value="Yes"
                                                    checked={formData.employedNow === "Yes"}
                                                    onChange={handleChange}
                                                    className="form-radio text-blue-500"
                                                />
                                                <span className="ml-2">Yes</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="employedNow"
                                                    value="No"
                                                    checked={formData.employedNow === "No"}
                                                    onChange={handleChange}
                                                    className="form-radio text-blue-500"
                                                />
                                                <span className="ml-2">No</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="contactEmployer" className="block mb-2">If so, may we contact your employer?</label>
                                        <div className="flex items-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="contactEmployer"
                                                    value="Yes"
                                                    checked={formData.contactEmployer === "Yes"}
                                                    onChange={handleChange}
                                                    className="form-radio text-blue-500"
                                                />
                                                <span className="ml-2">Yes</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="contactEmployer"
                                                    value="No"
                                                    checked={formData.contactEmployer === "No"}
                                                    onChange={handleChange}
                                                    className="form-radio text-blue-500"
                                                />
                                                <span className="ml-2">No</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="appliedBefore" className="block mb-2">Ever applied to this company before?</label>
                                        <div className="flex items-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="appliedBefore"
                                                    value="Yes"
                                                    checked={formData.appliedBefore === "Yes"}
                                                    onChange={handleChange}
                                                    className="form-radio text-blue-500"
                                                />
                                                <span className="ml-2">Yes</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="appliedBefore"
                                                    value="No"
                                                    checked={formData.appliedBefore === "No"}
                                                    onChange={handleChange}
                                                    className="form-radio text-blue-500"
                                                />
                                                <span className="ml-2">No</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="md:col-span-2">
                                        <label htmlFor="whenApplied">When Applied</label>
                                        <input
                                            type="date"
                                            name="whenApplied"
                                            id="whenApplied"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.whenApplied}
                                            onChange={handleChange}
                                            disabled={formData.appliedBefore !== 'Yes'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Former Employers Section */}
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 mt-8">
                            <div className="text-black">
                                <p className="font-medium text-xl">Former Employers</p>
                                <p>Please fill out all the fields.</p>
                                <p>List your last four employers, starting with the most recent one first.</p>
                            </div>

                            <div className="lg:col-span-2">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                    <h2 className='md:col-span-5 underline text-lg'>Employer 1</h2>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer1FromDate">From</label>
                                        <input
                                            type="text"
                                            name="employer1FromDate"
                                            id="employer1FromDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer1FromDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer1ToDate">To</label>
                                        <input
                                            type="text"
                                            name="employer1ToDate"
                                            id="employer1ToDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer1ToDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-2">
                                        <label htmlFor="employer1Name">Employer Name</label>
                                        <input
                                            type="text"
                                            name="employer1Name"
                                            id="employer1Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer1Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer1Phone">Employer Phone Number</label>
                                        <input
                                            type="text"
                                            name="employer1Phone"
                                            id="employer1Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer1Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label htmlFor="employer1Address">Employer Address</label>
                                        <input
                                            type="text"
                                            name="employer1Address"
                                            id="employer1Address"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer1Address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer1Position">Position</label>
                                        <input
                                            type="text"
                                            name="employer1Position"
                                            id="employer1Position"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer1Position}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer1Salary">Salary</label>
                                        <input
                                            type="text"
                                            name="employer1Salary"
                                            id="employer1Salary"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer1Salary}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-5">
                                        <label htmlFor="employer1Reason">Reason for leaving</label>
                                        <input
                                            type="text"
                                            name="employer1Reason"
                                            id="employer1Reason"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer1Reason}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <h2 className='md:col-span-5 underline text-lg'>Employer 2</h2>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer2FromDate">From</label>
                                        <input
                                            type="text"
                                            name="employer2FromDate"
                                            id="employer2FromDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer2FromDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer2ToDate">To</label>
                                        <input
                                            type="text"
                                            name="employer2ToDate"
                                            id="employer2ToDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer2ToDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-2">
                                        <label htmlFor="employer2Name">Employer Name</label>
                                        <input
                                            type="text"
                                            name="employer2Name"
                                            id="employer2Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer2Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer2Phone">Employer Phone Number</label>
                                        <input
                                            type="text"
                                            name="employer2Phone"
                                            id="employer2Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer2Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label htmlFor="employer2Address">Employer Address</label>
                                        <input
                                            type="text"
                                            name="employer2Address"
                                            id="employer2Address"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer2Address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer2Position">Position</label>
                                        <input
                                            type="text"
                                            name="employer2Position"
                                            id="employer2Position"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer2Position}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer2Salary">Salary</label>
                                        <input
                                            type="text"
                                            name="employer2Salary"
                                            id="employer2Salary"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer2Salary}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-5">
                                        <label htmlFor="employer2Reason">Reason for leaving</label>
                                        <input
                                            type="text"
                                            name="employer2Reason"
                                            id="employer2Reason"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer2Reason}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <h2 className='md:col-span-5 underline text-lg'>Employer 3</h2>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer3FromDate">From</label>
                                        <input
                                            type="text"
                                            name="employer3FromDate"
                                            id="employer3FromDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer3FromDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer3ToDate">To</label>
                                        <input
                                            type="text"
                                            name="employer3ToDate"
                                            id="employer3ToDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer3ToDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-2">
                                        <label htmlFor="employer3Name">Employer Name</label>
                                        <input
                                            type="text"
                                            name="employer3Name"
                                            id="employer3Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer3Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer3Phone">Employer Phone Number</label>
                                        <input
                                            type="text"
                                            name="employer3Phone"
                                            id="employer3Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer3Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label htmlFor="employer3Address">Employer Address</label>
                                        <input
                                            type="text"
                                            name="employer3Address"
                                            id="employer3Address"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer3Address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer3Position">Position</label>
                                        <input
                                            type="text"
                                            name="employer3Position"
                                            id="employer3Position"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer3Position}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer3Salary">Salary</label>
                                        <input
                                            type="text"
                                            name="employer3Salary"
                                            id="employer3Salary"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer3Salary}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-5">
                                        <label htmlFor="employer3Reason">Reason for leaving</label>
                                        <input
                                            type="text"
                                            name="employer3Reason"
                                            id="employer3Reason"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer3Reason}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <h2 className='md:col-span-5 underline text-lg'>Employer 4</h2>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer4FromDate">From</label>
                                        <input
                                            type="text"
                                            name="employer4FromDate"
                                            id="employer4FromDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer4FromDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer4ToDate">To</label>
                                        <input
                                            type="text"
                                            name="employer4ToDate"
                                            id="employer4ToDate"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            placeholder="MM/YYYY"
                                            value={formData.employer4ToDate}
                                            onChange={handleChange}
                                            pattern="\d{2}/\d{4}"
                                            title="Please enter a date in MM/YYYY format"
                                        />
                                    </div>
                                    <div className="md:col-span-2">
                                        <label htmlFor="employer4Name">Employer Name</label>
                                        <input
                                            type="text"
                                            name="employer4Name"
                                            id="employer4Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer4Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer4Phone">Employer Phone Number</label>
                                        <input
                                            type="text"
                                            name="employer4Phone"
                                            id="employer4Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer4Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-3">
                                        <label htmlFor="employer4Address">Employer Address</label>
                                        <input
                                            type="text"
                                            name="employer4Address"
                                            id="employer4Address"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer4Address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer4Position">Position</label>
                                        <input
                                            type="text"
                                            name="employer4Position"
                                            id="employer4Position"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer4Position}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="employer4Salary">Salary</label>
                                        <input
                                            type="text"
                                            name="employer4Salary"
                                            id="employer4Salary"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer4Salary}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-5">
                                        <label htmlFor="employer4Reason">Reason for leaving</label>
                                        <input
                                            type="text"
                                            name="employer4Reason"
                                            id="employer4Reason"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.employer4Reason}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* References Section */}
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 mt-8">
                            <div className="text-black">
                                <p className="font-medium text-xl">References</p>
                                <p>Please fill out all the fields.</p>
                                <p>List three persons not related to you, whom you have known at least one year. </p>
                            </div>

                            <div className="lg:col-span-2">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

                                    {/* Reference 1*/}
                                    <div className="md:col-span-2">
                                        <label htmlFor="ref1Name">Full Name</label>
                                        <input
                                            type="text"
                                            name="ref1Name"
                                            id="ref1Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref1Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref1Phone">Phone Number</label>
                                        <input
                                            type="text"
                                            name="ref1Phone"
                                            id="ref1Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref1Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref1Relationship">Relationship</label>
                                        <input
                                            type="text"
                                            name="ref1Relationship"
                                            id="ref1Relationship"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref1Relationship}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref1YearsAcq">Years Acquainted</label>
                                        <input
                                            type="text"
                                            name="ref1YearsAcq"
                                            id="ref1YearsAcq"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref1YearsAcq}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Reference 2*/}
                                    <div className="md:col-span-2">
                                        <label htmlFor="ref2Name">Full Name</label>
                                        <input
                                            type="text"
                                            name="ref2Name"
                                            id="ref2Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref2Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref2Phone">Phone Number</label>
                                        <input
                                            type="text"
                                            name="ref2Phone"
                                            id="ref2Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref2Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref2Relationship">Relationship</label>
                                        <input
                                            type="text"
                                            name="ref2Relationship"
                                            id="ref2Relationship"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref2Relationship}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref2YearsAcq">Years Acquainted</label>
                                        <input
                                            type="text"
                                            name="ref2YearsAcq"
                                            id="ref2YearsAcq"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref2YearsAcq}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Reference 3*/}
                                    <div className="md:col-span-2">
                                        <label htmlFor="ref3Name">Full Name</label>
                                        <input
                                            type="text"
                                            name="ref3Name"
                                            id="ref3Name"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref3Name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref3Phone">Phone Number</label>
                                        <input
                                            type="text"
                                            name="ref3Phone"
                                            id="ref3Phone"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref3Phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref3Relationship">Relationship</label>
                                        <input
                                            type="text"
                                            name="ref3Relationship"
                                            id="ref3Relationship"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref3Relationship}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-span-1">
                                        <label htmlFor="ref3YearsAcq">Years Acquainted</label>
                                        <input
                                            type="text"
                                            name="ref3YearsAcq"
                                            id="ref3YearsAcq"
                                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                                            value={formData.ref3YearsAcq}
                                            onChange={handleChange}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="signature-section flex flex-col items-center justify-center mt-5 mx-auto">
                            <label htmlFor="signature" className="mb-2 text-center">Signature</label>
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{
                                    width: 300,
                                    height: 100,
                                    className: 'sigCanvas border-2 border-gray-400 rounded-md'
                                }}
                            />
                            <button
                                className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline my-4"
                                onClick={handleClearSignature}
                            >
                                Clear Signature
                            </button>
                        </div>

                    </form>
                    <div className="md:col-span-5 md:text-right text-center mt-5">
                        <div className="inline-flex md:items-end items-center">
                            <button
                                className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                onClick={handleButtonClick}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ApplicationModal
                isOpen={isApplicationModalOpen}
                onClose={() => setApplicationModalOpen(false)}
                handleSubmit={handleSubmit}
                formData={formData} // Pass formData if needed inside the modal
            />
        </div>
    );
};

export default ApplicationForm;
