import React, { useState } from 'react';
import axios from 'axios';

const EvaluationModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        ContactNumber: '',
        Email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form data:', formData);

        try {
            // Push form data to Strapi
            const response = await axios.post('https://strapi.loveandfaithhospice.com/api/request-evaluation-forms', {
                data: {
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                    ContactNumber: formData.ContactNumber,
                    Email: formData.Email,
                },
            });
            console.log('Response from Strapi:', response.data);

            // Send email with form data
            const emailResponse = await axios.post('https://loveandfaithhospice.com:5001/send-email', {
                formType: 'evaluation',
                formData: {
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                    ContactNumber: formData.ContactNumber,
                    Email: formData.Email,
                },
            });
            console.log('Response from email API:', emailResponse.data);

            alert('Your evaluation form has been submitted successfully!');

            // Refresh the page after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting your evaluation form. Please try again later.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 font-twcen overflow-y-auto">
            <div className="bg-mainColor rounded-3xl shadow pt-4 px-4 pb-4 xl:w-1/2 w-3/4 max-h-full overflow-y-auto">
                <div className="flex xl:justify-end justify-between items-center pb-2">
                    <h3 className="xl:hidden block text-normal font-semibold text-white">Request an Evaluation</h3>
                    <button onClick={onClose} className="text-secondaryColor hover:bg-red-400 rounded-lg p-1">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                {/* Desktop View */}
                <div className="hidden xl:block border-4 border-secondaryColor rounded-3xl p-2">
                    <div className="w-full flex flex-col items-center justify-center mx-auto px-2">
                        <div className='w-2/3 text-center justify-center items-center my-auto'>
                            <h2 className="mt-4 text-5xl text-white">Request an Evaluation</h2>
                            <p className="mb-4 text-2xl text-white">
                                Please fill out the form to request an evaluation.
                            </p>
                        </div>
                        <div className="w-3/4 px-8 pb-4 rounded-2xl">
                            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                                <div className="mb-4">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="FirstName">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="FirstName"
                                        name="FirstName"
                                        value={formData.FirstName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="LastName">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="LastName"
                                        name="LastName"
                                        value={formData.LastName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="ContactNumber">
                                        Contact Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="ContactNumber"
                                        name="ContactNumber"
                                        value={formData.ContactNumber}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-white text-2xl font-bold mb-2" htmlFor="Email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="Email"
                                        name="Email"
                                        value={formData.Email}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="col-span-2 flex items-center justify-center mt-2">
                                    <button
                                        type="submit"
                                        className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Mobile View */}
                <div className="xl:hidden block bg-mainColor border-2 border-secondaryColor rounded-3xl max-h-[75vh] overflow-y-auto">
                    <div className="w-full flex flex-col items-center">
                        <div className='p-4 text-center my-auto'>
                            <p className="text-xl text-white leading-none">
                                Please fill out the form to request an evaluation.
                            </p>
                        </div>
                        <div className="bg-mainColor px-5 pb-2 rounded-2xl w-full text-center mb-2">
                            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-1">
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="FirstName">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="FirstName"
                                        name="FirstName"
                                        value={formData.FirstName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="LastName">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="LastName"
                                        name="LastName"
                                        value={formData.LastName}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="ContactNumber">
                                        Contact Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="ContactNumber"
                                        name="ContactNumber"
                                        value={formData.ContactNumber}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="mb-1">
                                    <label className="block text-white text-xl font-bold mb-1" htmlFor="Email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="Email"
                                        name="Email"
                                        value={formData.Email}
                                        onChange={handleChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                        required
                                    />
                                </div>
                                <div className="flex items-center justify-center mt-2">
                                    <button
                                        type="submit"
                                        className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvaluationModal;
