import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Blazy from 'blazy';
import { IoIosArrowRoundForward } from "react-icons/io";
import { InView } from 'react-intersection-observer';
import Slideshow from '../components/home_photoslider';
import DefaultButton from '../components/default_button';
import lovefaithwhitelogo from '../assets/LogoTransparentWebWhite.png';
import welcomeImg from '../assets/welcomeimg.jpg';
import welcomeBGImg from '../assets/welcomebg.jpg';
import evaluation from '../assets/evaluation.png';
import insurance from '../assets/insuranceflaticon.png';
import healthcare1 from '../assets/healthcareteam1.png';
import healthcare2 from '../assets/healthcareteam2.png';
import healthcare3 from '../assets/healthcareteam3.png';
import healthcare4 from '../assets/healthcareteam4.png';
import CareerForm from '../components/forms/careerform';
import CareerFormMobile from '../components/forms/careerform_mobile';
import careersectionBGImg from '../assets/careersectionbg.jpg';
import AssessmentModal from '../components/forms/assessmentform';
import EvaluationModal from '../components/forms/evaluationform';
import InsuranceModal from '../components/insurancemodal';

const GradientOverlay = ({ children }) => (
    <div
        className="relative w-full h-full"
        style={{
            backgroundImage: 'linear-gradient(to top, transparent 2%, white 100%)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }}
    >
        {children}
    </div>
);

const Home = () => {
    useEffect(() => {
        const bLazy = new Blazy({
            selector: '.b-lazy',
        });

        return () => bLazy.destroy();
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEvaluationModalOpen, setEvaluationModalOpen] = useState(false);
    const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const options = {
        triggerOnce: true,
        threshold: 0.1
    };

    return (
        <main className="w-full flex-grow flex flex-col items-center justify-center overflow-x-hidden text-center z-49 xl:mt-[-112px]">
            {/* Section One */}
            {/* Section for Desktop View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="w-full xl:h-[100vh] h-100vh relative text-white font-twcen overflow-hidden xl:block hidden z-48">
                        <div className="h-full absolute inset-0 z-47">
                            <Slideshow />
                        </div>

                        {/* Foreground content */}
                        <div className="relative z-49 flex flex-row h-full justify-evenly px-20 mr-10 2xl:pb-40 pb-12">
                            <motion.div
                                initial={{ opacity: 0, x: -150 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.7 }}
                                className="flex flex-col justify-end text-left w-3/4 ml-14 mb-10 drop-shadow-md">
                                <h2 className="text-3xl ml-0">
                                    <span className="font-semibold text-white text-5xl">TRUST</span> IN THE
                                    <span className="font-semibold text-white text-5xl"> LORD</span>
                                </h2>
                                <h2 className="text-3xl ml-3">WITH ALL YOUR HEART</h2>
                                <h2 className="text-3xl ml-8">DO NOT DEPEND ON YOUR</h2>
                                <h2 className="text-3xl ml-12">
                                    <span className="font-semibold text-white text-5xl">OWN</span> UNDERSTANDING
                                </h2>
                                <div className='ml-20'>
                                    <h2 className="text-3xl">
                                        <span className="font-semibold text-white text-5xl">SEEK</span> HIS WILL
                                    </h2>
                                    <h2 className="text-3xl ml-4">IN ALL YOU DO AND HE WILL</h2>
                                    <h2 className="text-3xl ml-8">
                                        <span className="font-semibold text-white text-5xl">DIRECT</span> YOUR PATHS
                                    </h2>
                                    <h2 className="text-xl text-left ml-20 pl-20">Proverbs 3:5</h2>
                                </div>
                            </motion.div>

                            <motion.div
                                initial={{ opacity: 0, x: 150 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.7 }}
                                className="flex flex-col justify-end items-end mb-10 mt-5 drop-shadow-md drop-shadow-offset-x-1 drop-shadow-offset-y-1 drop-shadow-blur-1">
                                <img src={lovefaithwhitelogo} alt="Welcome" style={{ width: '640px' }} className="h-auto pb-5 mt-5" />
                                <p className="text-3xl mb-4 text-right">
                                    Our goal is to ensure that patients receive the best end of life care with dignity and
                                    compassion.
                                </p>

                                <motion.div
                                    initial={{ opacity: 0, y: 150, x: 150 }}
                                    animate={inView ? { opacity: 1, y: 0, x: 0 } : {}}
                                    transition={{ duration: 0.7, delay: 0.5 }}
                                    className="flex flex-row gap-x-4 mt-5">
                                    <DefaultButton text="Submit your Question Here" width="w-80" height="h-14" to="/contact#help" />
                                    <DefaultButton text="Schedule an Assessment" width="w-80" height="h-14" onClick={openModal} />
                                </motion.div>
                            </motion.div>
                        </div>
                    </section>
                )}
            </InView>

            {/* Section for Mobile View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="relative w-full h-auto bg-neutral-800 text-white font-twcen overflow-hidden xl:hidden block xl:mt-0 mt-40">
                        <div className="absolute inset-0 z-0">
                            <Slideshow />
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: -150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7 }}>

                            <div className="relative z-10 flex flex-row h-full justify-evenly px-5 py-5 drop-shadow-md">
                                <div className="flex flex-col justify-center text-center w-auto lead">
                                    <h2 className="text-lg -mb-1">
                                        <span className="font-bold">TRUST</span> IN THE
                                        <span className="font-semibold"> LORD</span>
                                    </h2>
                                    <h2 className="text-lg -mb-1">WITH ALL YOUR HEART</h2>
                                    <h2 className="text-lg -mb-1">DO NOT DEPEND ON YOUR</h2>
                                    <h2 className="text-lg -mb-1">
                                        <span className="font-semibold">OWN</span> UNDERSTANDING
                                    </h2>
                                    <h2 className="text-lg -mb-1">
                                        <span className="font-bold">SEEK</span> HIS WILL
                                    </h2>
                                    <h2 className="text-lg -mb-1">IN ALL YOU DO AND HE WILL</h2>
                                    <h2 className="text-lg -mb-1">
                                        <span className="font-semibold">DIRECT</span> YOUR PATHS
                                    </h2>
                                    <h2 className="text-md">Proverbs 3:5</h2>
                                </div>
                            </div>
                        </motion.div>
                    </section>
                )}
            </InView>
            <InView {...options}>
                {({ inView, ref }) => (
                    <motion.div
                        initial={{ opacity: 0, y: 150 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.7 }}>

                        <section ref={ref} className="w-full h-auto bg-neutral-800 text-white font-twcen overflow-hidden xl:hidden block">
                            <div className="flex flex-col items-center my-2 mx-2 z-20">
                                <p className="m-4 text-2xl">
                                    Our goal is to ensure that patients receive the best end of life care with dignity and compassion.
                                </p>

                                <div className="flex flex-col items-center justify-center gap-y-4 mb-5">
                                    <DefaultButton text="Submit your Question Here" width="w-80" height="h-14" to="/contact#help" />
                                    <DefaultButton text="Schedule an Assessment" width="w-80" height="h-14" onClick={openModal} />
                                </div>
                            </div>
                        </section>
                    </motion.div>
                )}
            </InView>

            {/* Section Two */}
            {/* Section for Desktop View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref}
                        className="w-full h-[100vh] relative text-black font-twcen overflow-hidden xl:block hidden"
                        style={{
                            backgroundImage: `url(${welcomeBGImg})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    >
                        <GradientOverlay>
                            <div className="flex flex-row justify-center items-center h-full">
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={inView ? { opacity: 1, y: 0 } : {}}
                                    transition={{ duration: 0.7, delay: 1 }}>
                                    <div className='bg-mainColor pb-5 mx-20' style={{ borderRadius: "60px" }}>
                                        <img src={welcomeImg} alt="Welcome" style={{ width: '420px', borderRadius: "60px" }} className="h-auto rounded-3xl" />
                                    </div>
                                </motion.div>
                                <div className='w-1/2 flex flex-col justify-center items-center'>
                                    <motion.div
                                        initial={{ opacity: 0, y: 150 }}
                                        animate={inView ? { opacity: 1, y: 0 } : {}}
                                        transition={{ duration: 0.7, delay: 1 }}
                                        className="text-left my-5">
                                        <h2 className="mx-4 mt-4 text-4xl text-shadow-lg">WELCOME TO</h2>
                                        <h2 className="mx-4 mb-4 pb-5 text-5xl leading-none text-shadow-lg text-mainColor">LOVE & FAITH HOSPICE SERVICES INC.</h2>
                                        <p className="mx-4 mb-4 text-2xl">
                                            Hospice care helps individuals with an advanced life-limiting illness to make the most out of their lives. Our team of compassionate care professionals and volunteers offer support and comfort for patients and families when the patient optimally used all the available modern treatments already and/or is personally resolved to get dignified palliative/hospice care.
                                        </p>
                                    </motion.div>
                                    <motion.div
                                        initial={{ opacity: 0, x: 150 }}
                                        animate={inView ? { opacity: 1, x: 0 } : {}}
                                        transition={{ duration: 0.7, delay: 1 }}
                                        className="bg-mainColor text-white py-1 2xl:mt-16 mt-2 mx-5" style={{ borderRadius: "40px" }}>
                                        <div
                                            className="flex flex-row justify-around items-center border-2 border-secondaryColor rounded-3xl mx-3 my-3 px-16 py-8"
                                            style={{ borderRadius: "36px" }}
                                        >
                                            <div className='mx-2'>
                                                <img src={evaluation} alt="Evaluation icon" className="w-20 h-20 mb-2 mx-4" />
                                            </div>
                                            <div className='mx-2'>
                                                <h2 className="2xl:text-5xl text-2xl">FREE EVALUATION</h2>
                                                <h2 className="2xl:text-4xl text-lg">NO OUT-OF-POCKET COST.</h2>
                                            </div>
                                            <motion.div
                                                className="flex justify-center items-center pl-10"
                                                animate={{ x: [0, 5, -5, 0] }}
                                                transition={{ repeat: Infinity, duration: 1.5 }}
                                            >
                                                <IoIosArrowRoundForward
                                                    className='text-7xl font-bold text-secondaryColor cursor-pointer'
                                                    onClick={() => setEvaluationModalOpen(true)}
                                                />
                                            </motion.div>
                                        </div>
                                    </motion.div>

                                </div>
                            </div>
                        </GradientOverlay>
                    </section>
                )}
            </InView>

            {/* Section for Mobile View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="relative w-full h-auto bg-white text-white font-twcen overflow-hidden xl:hidden block">
                        <div className='w-full flex flex-row justify-center items-center text-black'>
                            <motion.div
                                initial={{ opacity: 0, y: 150 }}
                                animate={inView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.7, delay: 0.7 }}
                                className="text-left my-5">
                                <h2 className="mx-4 mt-4 text-4xl text-shadow-lg">WELCOME TO</h2>
                                <h2 className="mx-4 mb-4 pb-5 text-5xl leading-none text-shadow-lg text-mainColor">LOVE & FAITH HOSPICE SERVICES INC.</h2>
                                <p className="mx-4 mb-4 text-2xl">
                                    Hospice care helps individuals with an advanced life-limiting illness to make the most out of their lives. Our team of compassionate care professionals and volunteers offer support and comfort for patients and families when the patient optimally used all the available modern treatments already and/or is personally resolved to get dignified palliative/hospice care.
                                </p>
                            </motion.div>
                        </div>

                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 0.8 }}>

                            <div className="bg-mainColor text-white py-1 my-5 mx-6" style={{ borderRadius: "40px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-secondaryColor rounded-3xl mx-2 my-5 px-8 py-4"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <img src={evaluation} alt="Evaluation icon" className="w-20 h-20 mb-2" />
                                    <div>
                                        <h2 className="text-4xl">FREE EVALUATION</h2>
                                        <p className="my-3 text-xl leading-none">
                                            NO OUT-OF-POCKET COST.
                                        </p>
                                    </div>
                                    <motion.div
                                        className="flex justify-center items-center"
                                        animate={{ x: [0, 5, -5, 0] }}
                                        transition={{ repeat: Infinity, duration: 1.5 }}>

                                        <div className="flex justify-start items-center">
                                            <IoIosArrowRoundForward
                                                className='text-5xl font-bold text-secondaryColor cursor-pointer'
                                                onClick={() => setEvaluationModalOpen(true)}
                                            />
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </motion.div>
                    </section>
                )}
            </InView>

            {/* Section Three */}
            {/* Section for Desktop View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="w-full h-full relative text-white font-twcen overflow-hidden xl:block hidden">
                        <motion.div
                            initial={{ opacity: 0, }}
                            animate={inView ? { opacity: 1, } : {}}
                            transition={{ duration: 0.7, delay: 0.5 }}>

                            <div className="flex flex-col justify-center items-center text-center my-5">
                                <h2 className="mx-4 mb-4 py-5 text-6xl leading-none text-shadow-md text-black">SERVICES WE OFFER</h2>
                                <p className="mx-5 mb-4 text-2xl text-black leading-none">
                                    We have a variety of services tailored to meet each patient's needs.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 0.7 }}>

                            <div className="flex justify-center items-center w-full mx-5">
                                <div className="bg-mainColor text-white py-4 my-2 w-2/5" style={{ borderRadius: "24px" }}>
                                    <div className="flex flex-col justify-center items-center border-2 border-mainColor rounded-3xl m-2 px-8" style={{ borderRadius: "36px" }}>
                                        <div>
                                            <h2 className="text-4xl font-bold leading-none">OUR HEALTH CARE TEAM</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 1 }}>

                            <div className="flex flex-wrap justify-center items-center mx-6 my-2 p-1">
                                <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                    <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                        <img src={healthcare1} alt="Insurance icon" className="w-20 h-20 my-2" />
                                        <div>
                                            <h2 className="text-xl ">LICENSED AND CERTIFIED</h2>
                                            <h2 className="text-xl -mt-2">PROFESSIONAL SERVICES</h2>
                                            <p className="text-xl leading-none">
                                                An assigned interdisciplinary team to oversee your care and individualized needs.
                                            </p>
                                        </div>
                                        <div className="hidden justify-start items-center">
                                            <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' onClick={() => setInsuranceModalOpen(true)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                    <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                        <img src={healthcare2} alt="Insurance icon" className="w-20 h-20 my-2" />
                                        <div>
                                            <h2 className="text-xl -mt-2">VOLUNTEER</h2>
                                            <p className="text-xl leading-none">
                                                We have volunteers committed to offer assistance.
                                            </p>
                                        </div>
                                        <div className="hidden justify-start items-center">
                                            <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 1.2 }}>

                            <div className="flex flex-wrap justify-center items-center mx-6 my-4 p-4">
                                <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                    <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                        <img src={healthcare3} alt="Insurance icon" className="w-20 h-20 my-2" />
                                        <div>
                                            <h2 className="text-xl -mt-2">DURABLE MEDICAL</h2>
                                            <h2 className="text-xl -mt-2">EQUIPMENT &</h2>
                                            <h2 className="text-xl -mt-2">OTHER SERVICES</h2>
                                            <p className="text-xl leading-none">
                                                We only rely on reputable brands for our supplies.
                                            </p>
                                        </div>
                                        <div className="hidden justify-start items-center">
                                            <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center items-center bg-mainColor text-white py-5 my-1 mx-3" style={{ width: "560px", borderRadius: "24px" }}>
                                    <div className="flex flex-col h-60 justify-center items-center border-2 border-secondaryColor rounded-3xl px-4 mx-4" style={{ width: "530px", borderRadius: "36px" }}>
                                        <img src={healthcare4} alt="Insurance icon" className="w-20 h-20 my-2" />
                                        <div>
                                            <h2 className="text-xl -mt-2">BEREAVEMENT</h2>
                                            <h2 className="text-xl -mt-2">SERVICES</h2>
                                            <p className="text-xl leading-none">
                                                Learn more about the hospice services we offer.
                                            </p>
                                        </div>
                                        <div className="hidden justify-start items-center">
                                            <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </section>
                )}
            </InView>

            {/* Section for Mobile View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="w-full h-auto relative bg-white text-black font-twcen overflow-hidden xl:hidden block px-2">
                        <motion.div
                            initial={{ opacity: 0, }}
                            animate={inView ? { opacity: 1, } : {}}
                            transition={{ duration: 0.7, }}>

                            <div className="flex flex-col justify-center items-center text-center my-5">
                                <h2 className="mx-4 mb-4 pb-5 text-4xl leading-none text-shadow-md text-black">SERVICES WE OFFER</h2>
                                <p className="mx-5 mb-4 text-2xl leading-none">
                                    We have a variety of services tailored to meet each patient's needs.
                                </p>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 0.2 }}>

                            <div className="bg-mainColor text-white py-1 my-8 mx-5" style={{ borderRadius: "24px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-mainColor rounded-3xl mx-2 my-5 px-8"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <div>
                                        <h2 className="text-4xl font-bold leading-none">OUR HEALTH CARE TEAM</h2>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, x: 150 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.7, delay: 1.3 }}>

                            <div className="bg-mainColor text-white py-1 my-5 mx-6" style={{ borderRadius: "24px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-secondaryColor rounded-3xl mx-2 my-5 px-8 py-2"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <img src={healthcare1} alt="Insurance icon" className="w-20 h-20 my-2" />
                                    <div>
                                        <h2 className="text-xl ">LICENSED AND CERTIFIED</h2>
                                        <h2 className="text-xl -mt-2">PROFESSIONAL SERVICES</h2>
                                        <p className="my-3 text-xl leading-none">
                                            An assigned interdisciplinary team to oversee your care and individualized needs.
                                        </p>
                                    </div>
                                    <div className="hidden justify-start items-center">
                                        <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: -150 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.7, delay: 1.4 }}>

                            <div className="bg-mainColor text-white py-1 my-5 mx-6" style={{ borderRadius: "24px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-secondaryColor rounded-3xl mx-2 my-5 px-8 py-2"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <img src={healthcare2} alt="Insurance icon" className="w-20 h-20 my-2" />
                                    <div>
                                        <h2 className="text-xl -mt-2">VOLUNTEER</h2>
                                        <p className="my-3 text-xl leading-none">
                                            We have volunteers committed to offer assistance.
                                        </p>
                                    </div>
                                    <div className="hidden justify-start items-center">
                                        <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 150 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.7, delay: 1.5 }}>

                            <div className="bg-mainColor text-white py-1 my-5 mx-6" style={{ borderRadius: "24px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-secondaryColor rounded-3xl mx-2 my-5 px-8 py-2"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <img src={healthcare3} alt="Insurance icon" className="w-20 h-20 my-2" />
                                    <div>
                                        <h2 className="text-xl -mt-2">DURABLE MEDICAL</h2>
                                        <h2 className="text-xl -mt-2">EQUIPMENT &</h2>
                                        <h2 className="text-xl -mt-2">OTHER SERVICES</h2>
                                        <p className="my-3 text-xl leading-none">
                                            We only rely on reputable brands for our supplies.
                                        </p>
                                    </div>
                                    <div className="hidden justify-start items-center">
                                        <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: -150 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.7, delay: 1.6 }}>

                            <div className="bg-mainColor text-white py-1 my-5 mx-6" style={{ borderRadius: "24px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-secondaryColor rounded-3xl mx-2 my-5 px-8 py-2"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <img src={healthcare4} alt="Insurance icon" className="w-20 h-20 my-2" />
                                    <div>
                                        <h2 className="text-xl -mt-2">BEREAVEMENT</h2>
                                        <h2 className="text-xl -mt-2">SERVICES</h2>
                                        <p className="my-3 text-xl leading-none">
                                            Learn more about the hospice services we offer.
                                        </p>
                                    </div>
                                    <div className="hidden justify-start items-center">
                                        <IoIosArrowRoundForward className='text-5xl font-bold text-secondaryColor' />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </section>
                )}
            </InView>

            {/* Section Four */}
            {/* Section for Desktop View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref}
                        className="w-full h-full relative bg-mainColor text-white font-twcen overflow-hidden xl:block hidden"
                    >
                        <div className="flex flex-row justify-center items-center my-5 mx-5 px-5">
                            <div className='w-1/2 flex flex-col justify-center items-center'>
                                <motion.div
                                    initial={{ opacity: 0, x: -150 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.7, delay: 0.7 }}>

                                    <div className="text-left my-5">
                                        <h2 className="mx-4 mt-4 text-4xl text-shadow-lg">ACCEPTED</h2>
                                        <h2 className="mx-4 mb-4 pb-5 text-5xl leading-none text-shadow-lg text-secondaryColor">INSURANCES</h2>
                                        <p className="mx-4 mb-4 text-2xl">
                                            Our hospice agency is committed to providing compassionate and comprehensive care to our patients. To ensure that financial concerns do not hinder access to our services, we accept a variety of insurance plans, including major government programs and managed care plans. Please contact our office for detailed information about the specific insurances we accept and to discuss your coverage options.
                                        </p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, x: -150 }}
                                    animate={inView ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.7, delay: 0.9 }}>

                                    <div className="bg-secondaryColor text-black py-1 mt-5 mx-5" style={{ borderRadius: "40px" }}>
                                        <div
                                            className=" flex flex-row justify-around items-center border-2 border-mainColor rounded-3xl mx-3 my-3 px-10 py-8"
                                            style={{ borderRadius: "36px" }}
                                        >
                                            <div className='mx-2'>
                                                <img src={insurance} alt="Insurance icon" className="2xl:w-20 2xl:h-20 w-12 h-12 mr-10" />
                                            </div>
                                            <div className='mx-2'>
                                                <h2 className="2xl:text-4xl text-2xl leading-none">We accept different insurance</h2>
                                                <h2 className="2xl:text-4xl text-2xl leading-none">plans for our services.</h2>
                                            </div>
                                            <motion.div
                                                className="flex justify-center items-center pl-10"
                                                animate={{ x: [0, 5, -5, 0] }}
                                                transition={{ repeat: Infinity, duration: 1.5 }}
                                            >
                                                <div className="flex justify-center items-center 2xl:pl-10">
                                                    <IoIosArrowRoundForward className='text-7xl font-bold text-mainColor' onClick={() => setInsuranceModalOpen(true)} />
                                                </div>
                                            </motion.div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            <motion.div
                                initial={{ opacity: 0, x: 150 }}
                                animate={inView ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.7, delay: 1.2 }}>

                                <div className='bg-mainColor pb-5 mx-20 ' style={{ borderRadius: "60px" }}>
                                    <img src={welcomeImg} alt="Welcome" style={{ width: '420px', borderRadius: "60px" }} className="h-auto rounded-3xl" />
                                </div>
                            </motion.div>
                        </div>
                    </section>
                )}
            </InView>

            {/* Section for Mobile View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="relative w-full h-auto bg-mainColor text-white font-twcen overflow-hidden xl:hidden block">
                        <motion.div
                            initial={{ opacity: 0, y: -150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 0.5 }}>

                            <div className="my-8 mx-5">
                                <h2 className="mx-4 mt-4 text-4xl text-shadow-lg">ACCEPTED</h2>
                                <h2 className="mx-4 mb-4 pb-5 text-5xl leading-none text-shadow-lg text-secondaryColor">INSURANCES</h2>
                                <p className="mx-4 mb-4 text-2xl">
                                    Our hospice agency is committed to providing compassionate and comprehensive care to our patients. To ensure that financial concerns do not hinder access to our services, we accept a variety of insurance plans, including major government programs and managed care plans. Please contact our office for detailed information about the specific insurances we accept and to discuss your coverage options.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 150 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.7, delay: 0.8 }}>

                            <div className="bg-secondaryColor text-white py-1 my-5 mx-6" style={{ borderRadius: "40px" }}>
                                <div
                                    className="flex flex-col justify-start items-center border-2 border-mainColor rounded-3xl mx-2 my-5 px-8 py-4"
                                    style={{ borderRadius: "36px" }}
                                >
                                    <img src={insurance} alt="Insurance icon" className="w-20 h-20 mb-2" />
                                    <div>
                                        <p className="text-black my-3 text-2xl leading-none">
                                            We accept different insurance plans for our services.
                                        </p>
                                    </div>
                                    <motion.div
                                        className="flex justify-center items-center"
                                        animate={{ x: [0, 5, -5, 0] }}
                                        transition={{ repeat: Infinity, duration: 1.5 }}>

                                        <div className="flex justify-start items-center">
                                            <IoIosArrowRoundForward className='text-5xl font-bold text-mainColor' onClick={() => setInsuranceModalOpen(true)} />
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </motion.div>
                    </section>
                )}
            </InView>

            {/* Section Five */}
            {/* Section for Desktop View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref}
                        className="w-full h-full text-black font-twcen overflow-hidden xl:block hidden"
                    >
                        <div className='h-full pt-10 pb-5'
                            style={{
                                backgroundImage: `url(${careersectionBGImg})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <motion.div
                                initial={{ opacity: 0, y: 150 }}
                                animate={inView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.7, delay: 0.7 }}>

                                <div className="flex w-full h-full items-center justify-center mb-10">
                                    <CareerForm />
                                </div>
                            </motion.div>
                        </div>
                    </section>
                )}
            </InView>

            {/* Section for Mobile View */}
            <InView {...options}>
                {({ inView, ref }) => (
                    <section ref={ref} className="relative w-full h-auto bg-mainColor font-twcen overflow-hidden xl:hidden block">
                        <div className='h-full pt-10 pb-5'
                            style={{
                                backgroundImage: `url(${careersectionBGImg})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <motion.div
                                initial={{ opacity: 0, y: 150 }}
                                animate={inView ? { opacity: 1, y: 0 } : {}}
                                transition={{ duration: 0.7, delay: 0.8 }}>

                                <div className="flex w-full h-full items-center justify-center mb-10">
                                    <CareerFormMobile />
                                </div>
                            </motion.div>
                        </div>
                    </section>
                )}
            </InView>
            <InsuranceModal isOpen={isInsuranceModalOpen} onClose={() => setInsuranceModalOpen(false)} />
            <EvaluationModal isOpen={isEvaluationModalOpen} onClose={() => setEvaluationModalOpen(false)} />
            <AssessmentModal isOpen={isModalOpen} onClose={closeModal} />
        </main >
    );
};

export default Home;
