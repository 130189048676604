import React, { useState } from 'react';
import axios from 'axios';

const VolunteerForm = () => {
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        ContactNumber: '',
        Email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form data:', formData);

        try {
            // Push form data to Strapi
            const response = await axios.post('https://strapi.loveandfaithhospice.com/api/volunteer-forms', {
                data: {
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                    ContactNumber: formData.ContactNumber,
                    Email: formData.Email,
                },
            });
            console.log('Response from Strapi:', response.data);

            // Send email with form data
            const emailResponse = await axios.post('https://loveandfaithhospice.com:5001/send-email', {
                formType: 'volunteer',
                formData: {
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                    ContactNumber: formData.ContactNumber,
                    Email: formData.Email,
                },
            });
            console.log('Response from email API:', emailResponse.data);

            alert('Your volunteer form has been submitted successfully!');

            // Refresh the page after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('There was an error submitting your volunteer form. Please try again later.');
        }
    };

    return (
        <div>
            <div className="hidden xl:block bg-mainColor border-4 border-mainColor rounded-3xl p-4">
                <div className="w-full mx-auto grid grid-cols-2 gap-8">
                    <div className='text-right justify-center items-center my-auto mx-auto'>
                        <h2 className="mt-4 text-5xl text-white">BE A VOLUNTEER</h2>
                        <h2 className="mb-4 pb-5 text-6xl leading-none text-secondaryColor">JOIN OUR TEAM</h2>
                        <p className="mb-4 text-2xl text-white">
                            If you want to be part of our TEAM, BE A VOLUNTEER.
                        </p>
                    </div>
                    <div className="bg-mainColor px-8 py-4 rounded-2xl">
                        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="FirstName">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="FirstName"
                                    name="FirstName"
                                    value={formData.FirstName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="LastName">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="LastName"
                                    name="LastName"
                                    value={formData.LastName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="ContactNumber">
                                    Contact Number
                                </label>
                                <input
                                    type="tel"
                                    id="ContactNumber"
                                    name="ContactNumber"
                                    value={formData.ContactNumber}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-2xl font-bold mb-2" htmlFor="Email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="Email"
                                    name="Email"
                                    value={formData.Email}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="col-span-2 flex items-center justify-center mt-2">
                                <button
                                    type="submit"
                                    className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="xl:hidden block bg-mainColor border-4 border-mainColor rounded-3xl p-4">
                <div className="w-full mx-auto flex flex-col items-center">
                    <div className='text-center my-auto'>
                        <h2 className="mt-4 text-2xl text-white">BE A VOLUNTEER</h2>
                        <h2 className="mb-2 text-4xl leading-none text-secondaryColor">JOIN OUR TEAM</h2>
                        <p className="mb-4 text-2xl text-white">
                            If you want to be part of our TEAM, BE A VOLUNTEER.
                        </p>
                    </div>
                    <div className="bg-mainColor px-8 py-4 rounded-2xl w-full text-center">
                        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="FirstName">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="FirstName"
                                    name="FirstName"
                                    value={formData.FirstName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="LastName">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="LastName"
                                    name="LastName"
                                    value={formData.LastName}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="ContactNumber">
                                    Contact Number
                                </label>
                                <input
                                    type="tel"
                                    id="ContactNumber"
                                    name="ContactNumber"
                                    value={formData.ContactNumber}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-white text-xl font-bold mb-2" htmlFor="Email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="Email"
                                    name="Email"
                                    value={formData.Email}
                                    onChange={handleChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="flex items-center justify-center mt-2">
                                <button
                                    type="submit"
                                    className="bg-secondaryColor hover:bg-yellow-300 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VolunteerForm;
